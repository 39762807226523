import React from 'react';
import './Header.css';

const Header = () => {
  return (
    <header className="App-header">
      <h1>TOMASZ KRUPA</h1>
      <div className="animated-text">
        <p>WELCOME</p>
        <p>WELCOME</p>
        <p>WELCOME</p>
        <p>WELCOME</p>
        <p>WELCOME</p>
        <p>WELCOME</p>
        <p>WELCOME</p>
        <p>WELCOME</p>
        <p>WELCOME</p>
        <p>WELCOME</p>
      </div>
    </header>
  );
};

export default Header;